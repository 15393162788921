import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import BookNow from "./pages/BookNow";
import Layout from "./pages/Layout.jsx";
import AboutUs from "./pages/about.jsx";
import Terms from "./pages/terms.jsx";
import PrivacyPolices from "./pages/privacyPolices.jsx";
import Jobs from "./pages/jobs.jsx";
import ManageBooking from "./pages/manageBooking.jsx";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" index element={<Home />} />
          <Route path="/booknow" element={<BookNow />} />
          <Route path="/aboutus" element={<AboutUs />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/jobs" element={<Jobs />}></Route>
          <Route path="/managebooking" element={<ManageBooking />}></Route>
          <Route path="/privacy-policies" element={<PrivacyPolices />}></Route>
        </Route>
        <Route path="/*" element={<Layout />}>
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
