import style from "../../styles/BookNow.module.scss";
import { vehicles } from "../../Lib/vehicleList";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

// modal styling

export default function VehicleList({
  empty,
  setEmpty,
  quoteData,
  setFormScreen,
  setVehicleDetail,
  setVehicleType,
}) {
  // Define the order of vehicle types
  const orderOfTypes = [
    "Saloon",
    "Estate",
    "MPV+",
    "8 Passengers",
    "Executive",
  ];
  const sortedData = quoteData.sort(
    (a, b) =>
      orderOfTypes.indexOf(a.vehicleType) - orderOfTypes.indexOf(b.vehicleType)
  );
  const handleBooking = async (vehicle) => {
    await setVehicleDetail(vehicle);
    setVehicleType([vehicle.vehicleType]);
    setFormScreen(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to 'auto' for instant scrolling
    });
  };
  return (
    <div>
      {/* car listing component */}
      <ul className={style.vehicleListing}>
        {sortedData?.map((itm, indx) => {
          let vehicle = vehicles[itm.vehicleType];

          return (
            <li key={indx} className={style.listItem}>
              <div className={style.desc}>
                <img src={vehicle?.img} alt="" />
                <p>
                  MAX{" "}
                  <span style={{ fontWeight: 700 }}>
                    {vehicle?.capacity} PEOPLE{" "}
                  </span>
                  PER VEHICLE
                </p>
                <p>{vehicle?.description}</p>
              </div>
              <div className={style.heading}>
                <h2>{vehicle?.name}</h2>
                <p>starting from £{itm.price}</p>

                <button
                  className={style.button52}
                  role="button"
                  onClick={() => handleBooking(itm)}
                >
                  Book Now
                </button>
              </div>
            </li>
          );
        })}
      </ul>
      <Modal
        open={empty}
        onClose={() => setEmpty(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.emptyModal}>
          <div className={style.modalHeader}>
            <Button onClick={() => setEmpty(false)}>
              <CloseIcon />
            </Button>
            <p>Call us Now</p>
          </div>
          <div className={style.modalBody}>
            <p>It looks like there is an issue booking online at the moment.</p>
            <p>
              If you continue to have booking problems you can chat with us or
              <a href="tel:02084599999"> Call us on 02084599999</a> and we will
              take your booking over the phone
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
VehicleList.propTypes = {
  empty: PropTypes.bool,
  setEmpty: PropTypes.func,
  quoteData: PropTypes.shape([]),
  setFormScreen: PropTypes.func,
  setVehicleDetail: PropTypes.func,
  setVehicleType: PropTypes.func,
};
