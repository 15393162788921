export const airportsList = [
  {
    address: "Heathrow, Terminal 2",
    postcode: "TW6 1EW",
    formatted_address: ["Heathrow, Terminal 2"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Heathrow, Terminal 3",
    postcode: "TW6 1QG",
    formatted_address: ["Heathrow, Terminal 3"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Heathrow, Terminal 4",
    postcode: "TW6 3XA",
    formatted_address: ["Heathrow, Terminal 4"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Heathrow, Terminal 5",
    postcode: "TW6 2GA",
    formatted_address: ["Heathrow, Terminal 5"],
    latitude: "",
    longitude: "",
  },
  {
    address: "London City Airport, Royal Docks, London",
    postcode: "E16 2PX",
    formatted_address: ["London City Airport, Royal Docks, London"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Luton Airport",
    postcode: "LU2 9NE",
    formatted_address: ["Luton Airport"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Gatwick Airport, North Terminal",
    postcode: "RH6 0LX",
    formatted_address: ["Gatwick Airport, North Terminal"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Gatwick Airport, South Terminal",
    postcode: "RH6 0LA",
    formatted_address: ["Gatwick Airport, North Terminal"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Stansted Airport",
    postcode: "CM24 1PS",
    formatted_address: ["Stansted Airport"],
    latitude: "",
    longitude: "",
  },
  {
    address: "Southend Airport",
    postcode: "SS2 6YF",
    formatted_address: ["Southend Airport"],
    latitude: "",
    longitude: "",
  },
];
