// App.js
import { PayPalButtons } from "@paypal/react-paypal-js";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

export default function Paypal({ handleJobRequest, price }) {
  const [bookingDetails, setBookingDetails] = useState(price);
  // console.log(bookingData);
  // handle create order
  const createOrders = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: ``,
          amount: {
            value: bookingDetails,
          },
        },
      ],
    });
  };

  useEffect(() => {
    setBookingDetails(price);
    console.log(price);
  }, [price]);
  return (
    <PayPalButtons
      createOrder={createOrders}
      onApprove={async (data, actions) => {
        const order = await actions.order.capture();
        // console.log("order", order);
        handleJobRequest(order.id);
      }}
    />
  );
}
Paypal.propTypes = {
  handleJobRequest: PropTypes.func,
  bookingData: PropTypes.any,
  price: PropTypes.number,
};
