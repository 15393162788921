// get address api key
const API_KEY = "0dxWLuqbrka8B0V_sJ7tfA40773";
// get address api string
export const GET_ADDRESS_API = (value) => {
  return `https://api.getAddress.io/autocomplete/${value}?api-key=${API_KEY}`;
};
// get specific address credentials
export const GET_SPECIFIC_ADDRESS_API = (value) => {
  return `https://api.getAddress.io/get/${value}?api-key=${API_KEY}`;
};
export const BASE_URL = "https://dev-env.airportbee.com/api";
//
