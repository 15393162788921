// dependencies
import style from "../../styles/Home.module.scss";
// importing images of partners
import cabwise from "../../assets/images/cab_yello.gif";
// import cabubble from "../../../public/images/cabubble.png";
// partners list
const partners = [cabwise];
// partners layout section
export default function Partners() {
  return (
    <section className="container">
      <div className={style.partnersContainer}>
        <h2>Our Partners</h2>
        <ul>
          {partners.map((itm, indx) => (
            <li key={indx}>
              <img src={itm} alt="patners-image-air-port-bee" />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
