import style from "../styles/Footer.module.scss";
import badge from "../assets/images/badge.gif";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className={style.footer}>
      <section className={"container " + style.footerSection}>
        <div className={style.footerAboutSection}>
          <h4>About us</h4>
          <p>
            Airport Bee, part of Cheetah Ltd, is a TFL approved Private Hire
            Operator based in London. If you ever need a Heathrow Transfer, a
            journey from London to Gatwick, Airport Transfers from Stansted or a
            trip from Luton Airport to anywhere in the UK, we have got you
            covered. Pay for your London Airport Transfer using PayPal or cash.
          </p>
          <div
            class="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="56278e9abfbbba0bdcd568bc"
            data-businessunit-id="5c9e8cfa0668fb00011f04cc"
            data-style-height="52px"
            data-style-width="100%"
          >
            <a
              href="https://uk.trustpilot.com/review/www.cheetahcars.co.uk"
              target="_blank"
            >
              Trustpilot
            </a>
          </div>
        </div>
        <div className={style.footerHelpSection}>
          <h4>Need Help?</h4>
          <p>Contact us via chat, phone or email:</p>
          <a href="http://chat.cheetahcars.co.uk/chat/1e21h5hot">
            Chat with us <span style={{ color: "#FFCC00" }}>Here</span>
          </a>
          <a href="tel:+442084599999">
            Call: <span style={{ color: "#FFCC00" }}> +4420-8459-9999</span>
          </a>
          <a href="mailto:bookings@airportbee.com">
            Email us :
            <span style={{ color: "#FFCC00" }}> bookings@airportbee.com</span>
          </a>
        </div>
        <div>
          <h4 style={{ color: "#fff", marginBottom: 16 }}>Address</h4>
          <p style={{ color: "#fff" }}>148 Manor Park Road, London, NW10 4JR</p>
        </div>
      </section>
      <section className={style.footerNav + " container"}>
        <p>Copyright 2024, All rights reserved.</p>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/jobs">Jobs</Link>
          </li>
          <li>
            <Link to="/terms">Booking Terms</Link>
          </li>
          <li>
            <Link to="/privacy-policies">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/">Store Locator</Link>
          </li>
        </ul>
      </section>
    </footer>
  );
}
