import Hero from "../layouts/Home/Hero";
import OverView from "../layouts/Home/Overview";
import Partners from "../layouts/Home/Partners";
export default function Home() {
  return (
    <section>
      <Hero />
      <OverView />
      <Partners />
    </section>
  );
}
