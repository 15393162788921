import style from "../styles/terms.module.scss";
export default function TermsContent() {
  return (
    <section className={style.termsContent}>
      <p>
        Version: 1.8 <br /> <br />
        Last Revision: 22/08/2022 <br />
        <br />
        The agreement is drafted on the basis that it is governed by English
        law.
        <br />
        <br /> Please note references to "us", "we", "the company", or "our"
        refers to Cheetah Limited. References to "the customer(s)"", "you" or
        "your" refers to the Booker and or Passenger. <br />
        <br />
        By accessing or using our Services, you confirm your agreement to be
        bound by these Booking Terms. If you do not agree to these Booking
        Terms, you may not access or use the Services. Cheetah limited reserves
        the right to alter/change the Booking Terms as and when required.
        <br />
        <br /> To use our Services, you must register for and maintain an active
        personal user Account. To acquire an Account you must be at least 18
        years of age and your chosen method of payment must be registered to no
        one other than yourself. <br />
        <br />
        To successfully register an Account you must submit some personal
        information, this may include your name, contact mobile phone number,
        email address, as well as a valid payment method. By accessing or using
        our Service you agree to maintain true, precise, complete, and current
        information in your Account. You are liable for all activity that takes
        place under your Account including booked journeys, cancelled trips,
        payments etc. You are responsible for the security and confidentiality
        of your Account username and password at all times and as a result you
        are held liable for all transactions that take place via your Account.
        You may only possess one Account. <br />
        <br />
        You agree that we may contact you by email, telephone or text message(s)
        at any of the contact details provided by you or a third party on your
        behalf in relation to an Account, including for marketing purposes. By
        using our Service you agree that we can contact you at any time before,
        during or after your journey. You understand that you are not required
        to provide this consent as a condition of purchasing any goods or
        services.
        <br />
        <br /> The Operator is principal, for the purposes of Regulation 9(14)
        of the Private Hire Vehicles (London) (Operators' Licences) Regulations
        2000 (as amended), and acknowledges its responsibility to the Customer
        in accepting the booking and provision of the journey.
      </p>
      <ul>
        <li>
          <h3>1. Price</h3>
          <p>
            1.1 Fares
            <br />
            <br />
            The customer agrees to make a fare payment to the company in
            consideration of the provision of a private hire booking and
            journey. The Fare can be collected by the driver on behalf of the
            company. <br />
            <br />
            Pricing works on a fixed basis for all bookings types whether via a
            telephone, online portals including APPs, live chat, email or in
            person - Your final fare will be displayed on the Driver App! <br />
            <br />
            Your fare may exceed the quoted/estimate as this is merely a general
            fare guide – traffic, route selection, diversions, tolls, congestion
            charge (where applicable)and other such key factors may determine
            your final fare which is payable in full.
            <br />
            <br />
            The price you have been quoted includes one transfer from the pickup
            point to your destination. Your Fare has been quoted based on a
            route which is widely used by our fleet. If you decide to opt for an
            alternative route the Fare is subject to change which also includes
            any additional stops or vehicle upgrades.
            <br />
            <br />
            If your journey falls within our peak time fares for any given
            reason such as a delayed flight arrival time then the new peak time
            fare will supersede the journey estimate. Peak time fares start from
            06:50-09:00 & 14:30-19:00 Monday-Friday.
            <br />
            <br />
            We reserve the right to change prices as we see fit.
          </p>
          <p>
            1.2 Price going to Luton & Stansted– Free shuttle service
            <br />
            <br />
            Luton and Stansted Airport Free shuttle service are located by the
            Mid Stay Car parks. The Free shuttle service is provided by the
            Airport and runs on their own schedule directly to the airport
            terminal, please see the airport website for more information.
            <br />
            <br />
            If you decide to change your journey destination to the Express drop
            off point there will be an additional charge – see 1.3 for express
            drop off charges. You will need to call through or email so the
            booking can be correctly updated to your new drop off point.
            <br />
            <br />
          </p>
          <p>
            1.3 Additional Airport Drop off point charges - which have come into
            effect by the Airports.
            <br />
            <br /> The prices on display below are managed by the Airports.
            <br />
            <br />
            <table>
              <tr>
                <th>AIRPORT / TERMINAL </th>
                <td>ADDITIONAL FARE</td>
              </tr>
              <tr>
                <th>HEATHROW T1 TO T5 </th>
                <td>£5</td>
              </tr>
              <tr>
                <th>GATWICK NORTH TERMINAL EXPRESS DROP OFF </th>
                <td>£5</td>
              </tr>
              <tr>
                <th>GATWICK SOUTH TERMINAL EXPRESS DROP OFF </th>
                <td>£5</td>
              </tr>
              <tr>
                <th>LUTON EXPRESS DROP OFF </th>
                <td>£5</td>
              </tr>
              <tr>
                <th>STANSTED EXPRESS DROP OFF </th>
                <td>£7</td>
              </tr>
              <tr>
                <th>LONDON CITY AIRPORT EXPRESS DROP OFF </th>
                <td>£3.50</td>
              </tr>
            </table>
          </p>
          <br />
          <br />
          <p>
            1.4 Price collecting from the Airport
            <br />
            <br />
            The price you have been quoted includes one transfer from your
            chosen pickup Airport to your destination. The Fare will include a
            meet and greet based on the type you require (see prices in 1.5),
            flight tracking, and parking cost up to a maximum of £7
            <br />
            <br />
            If you are later than expected on an airport collection, you will be
            liable to pay waiting time plus a contribution towards any parking
            charges in addition to the fare quoted.
            <br />
            <br />
            Example <br />
            <br /> The fare selected is £43 <br />
            <br />
            Flight Arrival time: 04:00 pm <br />
            <br />
            Pick Up Time (after arrival/landing): 45minutes after <br />
            <br />
            The driver will aim to meet you at 04:45pm <br />
            <br /> No extra charges will be incurred, and you pay the driver £43{" "}
            <br />
            <br />
            But If you are late in getting out and meet the driver at 05:45pm –
            you pay the driver £71.00 <br />
            <br />
            How is my new fare calculated? <br />
            <br /> The New fare will be broken down into 3 elements <br />
            <br /> Airport Collection Fare - Waiting time - Parking Fee <br />
            <br />
            The Airport Collection Fare is fixed at £43 <br />
            <br /> The Additional waiting time is 60 minutes (60 Mins x 0.34p =
            £20) <br />
            <br /> The Parking Charge (hypothetical) £15.00 (£15.00 - £7inc in
            fixed fare = £8) <br />
            <br />
            New Fare is £43 + £20 + £8 = £71.00 <br />
            <br /> 1.5 ‘ Meet & Greet’ Collections at Airports <br />
            <br /> If you have requested a meet & greet service at the airport
            the price we have quoted you includes flight monitoring. <br />
            <br /> The prices on display below are Airport specific
            <br />
            <br />
            <table>
              <tr>
                <th>AIRPORT / TERMINAL </th>
                <td>ADDITIONAL FARE</td>
              </tr>
              <tr>
                <th>HEATHROW T1- T2 -T3 - T4-T5 (INSIDE) </th>
                <td>£7</td>
              </tr>
              <tr>
                <th>GATWICK NORTH - SOUTH (INSIDE) </th>
                <td>£7</td>
              </tr>
              <tr>
                <th>LUTON EXPRESS 10MIN PICKUP (OUTSIDE) </th>
                <td>£7</td>
              </tr>
              <tr>
                <th>LUTON PREMIUM MEET & GREET (INSIDE) </th>
                <td>£14</td>
              </tr>
              <tr>
                <th>STANSTED ZONE D EXPRESS PICKUP (OUTSIDE) </th>
                <td>£7</td>
              </tr>
              <tr>
                <th>STANSTED PREMIUM MEET & GREET (INSIDE) </th>
                <td>£15</td>
              </tr>
              <tr>
                <th>CITY AIRPORT PREMIUM MEET & GREET (INSIDE) </th>
                <td>£17</td>
              </tr>
            </table>
            <br />
            <br />
            If you are later than expected on an airport collection, you will be
            liable to pay waiting time plus a contribution towards any parking
            charges in addition to the fare quoted as seen above in 1.4 Price
            collecting from the airport. <br />
            <br />
            If the driver parking charge exceeds then the additional cost will
            be paid by the passenger. <br />
            <br />
            The parking charge will only increase in the event of a passenger
            being late – please ensure you give yourself sufficient hold off
            time after landing. The website has a default set at 45minutes, you
            are required to select a hold off period that suits you best. <br />
            <br />
            The driver will meet you inside the terminal only once contact can
            be made – unless a booking has been prepaid. You can make contact by
            calling the driver directly (please look out for the driver Arrival
            SMS when you land). Alternatively call our 24hr line on 0208 459 25
            25.
            <br />
            <br />
          </p>
          <p>
            1.6 Express pick up point collections at Stansted and Luton Airport
            <br />
            <br />
            Only 10 minutes are included free of charge from all express pickup
            points at Luton, London City and Stansted airport - parking charges
            reaching a maximum of £7 are inclusive of your fare – If parking
            exceeds £7 then you will need to the pay the difference.
            <br />
            <br />
          </p>
          <p>
            1.7 Via’s or Extra stops
            <br />
            <br />
            Any deviations on the journey will be charged as extras and will
            need to be agreed by us – a variation needs to be agreed on email or
            via telephone. A variation sent via the Booking comments will not be
            sufficient.
          </p>
        </li>
        <li>
          <h3>2. Waiting time & Parking Charges</h3>
          <p>
            2.1 Waiting time at collection address or at the Airport
            <br />
            <br /> If you are later than expected there will be waiting time
            charges applicable.
            <br />
            <br /> Waiting time is chargeable at 0.34pence per minute
            <br />
            <br /> Example <br />
            <br />
            Collection pre-booked for 13:00 pm
            <br />
            <br /> Driver Waiting time at collection for 30 minutes <br />
            <br />
            How is my new fare calculated? <br />
            <br />
            Driver Waiting time charge 30(Mins) x 0.34(pence per min) = £10.00
            <br />
            <br />
            The New Fare will include an additional £10.00 <br />
            <br />
            You are liable for any delays caused due to lost baggage, customs
            hold ups or other delays. A flight delay will be tracked and is not
            considered as a chargeable delay.
            <br />
            <br />
          </p>
          <p>
            2.2 Parking charges <br />
            <br />
            If there are parking charges applicable at your collection address,
            then this cost will need to be paid on top of the fare.
            <br />
            <br /> The parking charge at the airport is inclusive of the Fare
            only up to a maximum of a £7 parking ticket – if this is exceeded
            then the passenger is liable to pay for the difference. <br />
            <br />
            This varies for Luton / Stansted and City Airport Premium meet and
            greet services were if parking charges exceeds £10 you pay the
            difference (£15 parking ticket - £10 = £5 extra to be paid on top of
            Fare)
            <br />
            <br />
          </p>
          <p>
            2.3 Airport Pick Up Time
            <br />
            <br /> We will aim to pick you up at the Estimated Pick Up Time
            specified by you. The Estimated Pick Up Time is calculated as
            follows; Flight Arrival time + Pick up Time (after arrival/landing)
            <br />
            <br />
            Example <br />
            <br />
            Flight Arrival Time is 04:00pm + selected Pick up Time (after
            arrival/landing) 45 minutes, means your actual Estimated Pick up
            time will be 04:45pm
            <br />
            <br />
            2.4 Additional waiting time will be chargeable at £20 per hour but
            this does not include flight delays. For all value express pickups
            we are only able to offer a 10 minute window for swift collections.
            <br />
            <br />
            2.5 Flight arrival times are referenced from the public web sites of
            each respective airport we service. <br />
            <br />
            2.6 If you are going to the airport you have 5 minutes free waiting
            time from your place of collection for the time specified.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>3. Vehicle option</h3>
          <p>
            3.1 We will provide you with at least your minimum choice of vehicle
            option.
            <br />
            <br /> 3.2 Our fleet of actual vehicle models may differ from those
            illustrated on the website or app.
            <br />
            <br /> 3.3 All vehicle class changes will result in additional
            charges. Fares are based on passenger and baggage capacity
            combinations. <br />
            <br />
            <br />
            <br />
            In the event a driver fits your luggage in a smaller vehicle than
            what was required you are liable to pay the larger vehicle Fare
            <br />
            <br />
            Example <br />
            <br />
            You pre-booked a Saloon
            <br />
            <br /> Car A Saloon Car is dispatched <br />
            <br />
            Your luggage is excessive and suitable for an Estate/Intermediate
            Car <br />
            <br />
            Option 1- You are required to pay a cancellation fee whilst your
            larger vehicle is ordered (Fee 75% of the Fare) <br />
            <br />
            Option 2 - You and the driver decide to continue with the current
            car you are liable to pay the Fare for a larger car
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>4. Vehicle capacity</h3>
          <p>
            4.1 The passenger and baggage capacity of each vehicle type is
            clearly listed on the web site. It is your responsibility to ensure
            that the vehicle option you have chosen meets your transfer needs.
            If in doubt, please call or email us at bookings@cheetahcars.co.uk
            with any specific questions before making your booking.
          </p>
        </li>
        <li>
          <h3>5. Refusal</h3>
          <p>
            5.1 The driver has the right to refuse any passenger he/she feels
            may be a threat or danger to him/herself.
            <br />
            <br /> 5.2 The company has the right to refuse or cancel a booked
            transfer at any point in time. Whilst we aim to support all
            customers there are instances which result in matters beyond our
            reasonable control such as adverse weather conditions, fuel
            shortages, driver supply, and road closures impacting a safe
            collection
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>6. Responsibility</h3>
          <p>
            6.1 The Material on this site is provided as general information
            only and does not constitute the acceptance for any booking
            arrangements made. We accept no responsibility from any loss
            incurred. <br />
            <br />
            6.2 Furthermore we do not accept responsibility for any loss
            financial, professional, missed connection times, flights, trains
            for the driver being late due to adverse weather or traffic
            conditions including road traffic accidents/closures. The same
            applies with incorrect booking information supplied by the customer
            or persons booking.
            <br />
            <br /> 6.3 We are not liable for business losses. App(s)/Website(s)
            Services are provided only for private use. If you use
            App(s)/Website(s) Services for any commercial or business purpose we
            will have no liability to you for any loss of profit, loss of
            business, loss of revenue, business interruption, or loss of
            business opportunity (whether or not such loss could be reasonably
            expected to flow from our negligence or breach of these Terms).
            <br />
            <br /> 6.4 If we are liable to you in connection with the Booking
            Service or passenger transport, its liability, insofar as is
            permitted by law, will be limited to an amount equal to £500 in
            aggregate.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>7. Cancellations</h3>
          <p>
            7.1 Cancellations made up to 4 hours before your pickup date and
            time are free-of-charge. You can cancel a booking by going into ‘My
            Bookings’ on your app or clicking the 'Cancel Booking' icon link in
            our confirmation email. Alternatively, call through to our call
            centre to cancel a booking. <br />
            <br />
            7.2 Cancellations made less than 4 hours before your pickup date and
            time will be charged at 100%. You MUST call us (do not email) on
            +44(0)208-459-2525 if you wish to cancel less than 4 hours before
            the Pick Up time. <br />
            <br /> 7.3 We do not charge any administration costs when you cancel
            yourself online using the link we provide in our confirmation email.
            We reserve the right to charge a £5 administrative fee if you cancel
            over the telephone. <br />
            <br /> 7.4 If you paid using PayPal, Credit or Debit Card, we will
            normally provide refunds for cancellations immediately but in some
            cases it may take us up to 21 days.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>8. Booking Errors</h3>
          <p>
            8.1 If Booking Errors have been made and we have not been informed
            by telephone within 24 hours of the erroneous booking, then you
            agree to take full responsibility for our costs and any payment
            already made will be used to cover our costs. <br />
            <br />
            8.2 You may inform us by email (bookings@cheetahcars.co.uk) if your
            pickup time is more than 24 hours away. You agree to call us on
            +44(0)208-459-2525 if your booking is in less than 24 hours.
            <br />
            <br /> 8.3 We will not accept responsibility for booking errors if
            you do not inform us using the proper method stated in 8.2. <br />
            <br />
          </p>
        </li>
        <li>
          <h3>9. Changes</h3>
          <p>
            9.1 If you require a change to your pickup date and/or time or
            location, please email us at bookings@cheetahcars.co.uk.
            <br />
            <br /> 9.2 If your pick up date and time is in less than 24 hours,
            you agree to call us with any changes on +44(0)208-459-2525. <br />
            <br />
            9.3 We will not accept responsibility for booking changes if you do
            not inform us using the proper method stated in 9.2. <br />
            <br />
            9.4 You agree to keep us up to date on your itinerary, especially if
            you know your flight is going to be delayed or if you have a change
            in flight number. This will ensure the driver is able to meet and
            greet you in the right location/time and terminal. Always quote your
            booking reference when contacting us.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>10. Refund time</h3>
          <p>
            10.1 Please note that it may take up to 21 days for us to process
            any refunds.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>11. Meeting point</h3>
          <p>
            11.1 You agree to meet us at Our designated meeting point in the
            arrivals terminal building. The location of our driver meeting point
            will be clearly provided to you in our booking confirmation email.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>12. Baggage</h3>
          <p>
            12.1 You are responsible to load and unload your baggage onto the
            private hire vehicle(s). We cannot be held responsible for the loss
            or damage of your goods. You are responsible for your luggage and
            goods at all times. The drivers may assist you at their discretion,
            however, you agree that you will not hold the driver liable for any
            loss/damage to baggage or any items in/out of your baggage.
            <br />
            <br /> 12.2 It is your responsibility to select the correct vehicle
            type to meet your baggage needs at time of booking; the luggage
            capacity information or imagery shown is merely a guide and we do
            not accept responsibility if items cannot fit for any clarification
            you must call the office directly on the number provided. <br />
            <br />
            12.3 We will not carry luggage on seats and must be stowed in the
            rear boot of the vehicle. <br />
            <br />
            12.4 We do not accept responsibility for any claims related to the
            loss or damage of goods and luggage whilst in transit or post
            transfer.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>13. Infant, Child and Booster seats</h3>
          <p>
            13.1 Under UK law, you are not required to use a child seat in a
            private hire vehicle such as ours (more info here), however, we aim
            to provide these seats on-request but we do not guarantee their
            availability during peak periods or their suitability for your
            child. If the driver provides you with a child seat upon pickup, it
            is your sole responsibility to inspect it and ensure it meets your
            child's needs. <br />
            <br />
            13.2 The installation/fitting of any child seat(s) we provide and
            the buckling of a child is the sole responsibility of the customer.
            Usage of child seats provided by us are entirely at your discretion
            and you agree that the driver will not be held responsible for them
            in any way. <br />
            <br />
          </p>
        </li>
        <li>
          <h3>14. Zero tolerance on abuse</h3>
          <p>
            14.1 We reserve the right to refuse to transport or cease to
            transport any Passenger who behaves in a disorderly, threatening or
            abusive manner or who, in our absolute discretion, we consider a
            nuisance or a danger to our employees, agents, subcontractors or to
            fellow Passengers and may require such a Passenger to alight from a
            Passenger Vehicle and the Customer may be charged a Cancellation
            Fee. We are committed to providing Services in accordance with the
            Equality Act 2010. We may assist any Passenger who is not capable of
            boarding and alighting a Passenger Vehicle unaided, at our sole
            discretion but at the Passenger’s risk.
            <br />
            <br /> 14.2 We will prosecute any persons who intentionally cause
            damage to the vehicle or verbally abuse the driver.
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>15. Delays</h3>
          <p>
            15.1 We shall not be liable to the Customer or be deemed to be in
            breach of these terms by reason of any delay in performing any of
            our obligations.
            <br />
            <br /> 15.2 We shall not be liable to the Customer or be deemed to
            be in breach of these terms by reason of any failure to perform any
            of our obligations under these terms if the delay or failure was due
            to any circumstances or cause beyond our reasonable control.
          </p>
        </li>
        <li>
          <h3>16. Soiling Charge</h3>
          <p>
            16.1 The driver can charge the customer a soilage charge of up to
            £60 for soiling that requires the private hire vehicle to be taken
            out of service for cleaning as per TFL guidance.
            <br />
            <br />
            16.2 The customer shall compensate the Company against all losses,
            costs, damages and expenses arising from any act or omission of any
            passenger in their party
            <br />
            <br />
          </p>
        </li>
        <li>
          <h3>17. Complaints Procedure</h3>
          <p>
            17.1 If you have a reason for complaint, please provide full details
            by emailing (complaints@cheetahcars.co.uk). We aim to respond to all
            complaints within 5 to 7 working days.{" "}
          </p>
        </li>
      </ul>
    </section>
  );
}
