import style from "../styles/jobs.module.scss";
export default function JobsContent() {
  return (
    <section className={style.jobsContent}>
      <ul>
        <li>
          <h3>Driver (PCO) & Controller Jobs in London</h3>
          <p>
            We are always looking for enthusiastic, experienced and hard working
            PCO minicab drivers and Controllers. <br />
            <br />
            We are an organisation who is committed to the safe guarding of
            children & adults. <br />
            <br />
            To join our platform as an Independent Contractor it is essential to
            have the correct Licences in place. We can not allow you to perform
            rides until you meet the criteria which are set by TFL. The
            requirements may change from time to time as regulation and safety
            standards evolve - so it is best to visit the TFL website, for the
            latest guidelines visit:
            <a href=" https://tfl.gov.uk/info-for/taxis-and-private-hire/">
              {" "}
              https://tfl.gov.uk/info-for/taxis-and-private-hire/
            </a>
            <br />
            <br />
          </p>
        </li>
        <li>
          <h4>Driver Potential</h4>
          <ul>
            <li>Uncapped earnings up to £1000 per week</li>
            <li>Flexible working hours 'switch on when you want'</li>
            <li>
              Regular School runs and account work available via our B2B clients
            </li>
            <li>FREE Driver App for Android and iOS devices</li>
            <li>Work offered is both cash and account</li>
            <li>Higher rates for Executive and MPV vehicles</li>
          </ul>
        </li>
        <li>
          <h4>Staff Potential</h4>
          <ul>
            <li>Good competitive rates of pay</li>
            <li>Flexible working hours to help maintain work life balance</li>
            <li>Work with a friendly and professional operators team</li>
            <li>Potential to work from different locations</li>
          </ul>
        </li>
      </ul>
    </section>
  );
}
