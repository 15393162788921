import style from "../../styles/Home.module.scss";
import paypal from "../../assets/paypal-logo.png";
import { Link } from "react-router-dom";
export default function Hero() {
  return (
    <section className={style.hero}>
      <div className={style.heroContainer}>
        <h2>AIRPORT TRANSFERS</h2>
        <p>TO HEATHROW, LUTON, GATWICK AND UK-WIDE</p>
      </div>
      <div className={style.callToAction}>
        <span>
          <p>QUICK, EASY AND PAY WITH</p>
          <img src={paypal} alt="paypal" />
        </span>
        <Link to="/booknow">
          <button className={"actionBtn"}>Book Now</button>
        </Link>
      </div>
    </section>
  );
}
