import * as React from "react";
import style from "../styles/Header.module.scss";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import logo from "../assets/logo.png";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const drawerWidth = 240;

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <header className={style.header}>
      <nav className={style.navbar}>
        <span className={style.log}>
          <Link to="/">
            <img src={logo} alt="" className={style.logoIcon} />
          </Link>
        </span>
        <ul className={style.navList}>
          <li>
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <Link to={"/booknow"}>Book Now</Link>
          </li>
          <li>
            <Link to={"/managebooking"}>manage booking</Link>
          </li>
          <li>
            <Link to={"/aboutus"}>about us</Link>
          </li>
          {/* <li>
            <Link to={"/booknow"}>review</Link>
          </li> */}
        </ul>
        <Button onClick={() => setMobileOpen(true)} className={style.toggleBtn}>
          <MenuIcon />
        </Button>
      </nav>
      <nav>
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <ul className={style.mobileNavList}>
            <li onClick={handleDrawerToggle}>
              <Link to={"/"}>Home</Link>
            </li>
            <li onClick={handleDrawerToggle}>
              <Link to={"/booknow"}>Book Now</Link>
            </li>
            <li onClick={handleDrawerToggle}>
              <Link to={"/managebooking"}>Manage booking</Link>
            </li>
            <li onClick={handleDrawerToggle}>
              <Link to={"/aboutus"}>About us</Link>
            </li>
            {/* <li onClick={handleDrawerToggle}>
              <Link to={"/booknow"}>review</Link>
            </li> */}
          </ul>
        </Drawer>
      </nav>
    </header>
  );
}
