export const aboutUs = [
  {
    heading: "Our story",
    content:
      "Cheetah is a real company with a real passion to make sure we're giving a London wide service at local prices. It was licensed to trade by the London Borough of Brent via the Town and Country Planning Act 1962 – long before conventional London operator licenses were in existence. Cheetah is an establishment with a strong heritage, we were amongst the first companies to be licensed for private hire by the Public Carriage Office. Established in 1967, from very humble beginnings, the Cheetah Cars office, based out of North West London was manned by a handful of drivers. Today, thanks to our focus on our customers, we've grown to a multi-million pound business serving the good people of London.",
  },
  {
    heading: "Our mission",
    content:
      "The heart of what we do comes down to service. We believe that the service aspect of private hire in London has really hit rock-bottom. With the 'sat nav' driver culture, so much has changed in getting from A to B in a pleasurable manner. This is why our drivers receive comprehensive training to ensure their service standards are at the very high end of the industry. We monitor this aspect regularly and have trained staff monitoring our driver standards frequently. Additionally, our telephonist team is exclusively based in London - they know the areas and get to know our customers and business accounts. We have always had strong ties with the local community. This family feel has allowed Cheetah Cars to grow to the levels we see today.",
  },
  {
    heading: "Our vision",
    content:
      "With e-competitors such as Uber dominating the London private hire market, companies like ours have had to respond to really fill the gap. Such companies will never be able to provide our level of service 'at scale' where profits come before service. Cheetah's vision is and will always be about our service but also at a price that will never break the bank. We're doing so remarkably well but want to go further. We aim to ensure that all our customers consistently get a reliable and safe minicab service wherever they are in London - whether you are going to Westfield for that afternoon Sunday spend or if you need to go up the road to visit family - we really want Cheetah to be every Londoners #1 choice.",
  },
];
