import saloon from "../assets/images/saloon.png";
import estate from "../assets/images/estate.png";
import estate_old from "../assets/images/estate_old.png";
import seater from "../assets/images/8seater.png";
import executive from "../assets/images/executive.png";
import executive_old from "../assets/images/executive_old.png";
import mpv2_old from "../assets/images/mpv2_old.png";
import seater_old from "../assets/images/8seater_old.png";
import mpv_old from "../assets/images/mpv_old.png";
// vehicle list for quotes section
export const vehicles = {
  Saloon: {
    img: saloon,
    capacity: "4",
    description: "Up to 4 passengers and 2 suitcases",
    name: "Saloon",
  },
  Estate: {
    img: estate,
    capacity: "4",
    description: "4 Passengers with 3 suitcases and 1 cabin luggage",
    name: "Estate",
  },
  "MPV+": {
    img: mpv2_old,
    capacity: "6",
    description:
      "4 Passengers with 4 suitcases or 6 Passengers with cabin luggage only",
    name: "MPV Large",
  },
  "8 Passengers": {
    img: mpv2_old,
    capacity: "8",
    description: "Up to 7 Passengers with 6 suitcases & 6 cabin luggage",
    name: "8 Passengers",
  },
  Executive: {
    img: executive,
    capacity: "4",
    description: "4 Passengers with 2 suitcases and 2 cabin luggages",
    name: "Executive",
  },

  "Booster Seat 3+": {
    img: seater_old,
    capacity: "7",
    description: "7 PASSENGERS, 6 X 23KG SUITCASE & 6 X HAND LUGGAGE",
    name: "Full Size",
  },
  "Meet+Greet": {
    img: executive_old,
    capacity: "4",
    description: "1-2 X 23KG SUITCASE & 2 X HAND LUGGAGE",
    name: "Meet+Greet",
  },
  "Baby Seat": {
    img: mpv_old,
    capacity: "4",
    description: "1-2 X 23KG SUITCASE & 2 X HAND LUGGAGE",
    name: "Baby Seat",
  },
};
// vehicle list for just images
export const vehicleImgs = [saloon, estate, estate_old, seater, executive];
