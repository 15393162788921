import style from "../styles/jobs.module.scss";
import jobsBanner from "../assets/images/managin.webp";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
export default function ManageBooking() {
  return (
    <section>
      <div
        className={style.bannerSection}
        style={{ backgroundImage: `url(${jobsBanner})` }}
      ></div>

      <section className={style.manageBookingContainer + " container"}>
        <TextField label="Booking Reference" disabled />
        <TextField label="Email" disabled />
        <Button variant="contained" disabled>
          Search
        </Button>
      </section>
      <div className={style.comingSoon + " container"}>
        <p>Coming Soon</p>
      </div>
    </section>
  );
}
