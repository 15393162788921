// dependencies
import style from "../../styles/Home.module.scss";
import { vehicleImgs } from "../../Lib/vehicleList";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// over view point list
const points = [
  {
    heading: "HEATHROW & GATWICK",
    description:
      "Meet & Greet service at Heathrow Airport Terminals 1,2,3,4 and 5. Also to Gatwick Terminals N/S.",
  },
  {
    heading: "LUTON AND STANSTED",
    description:
      "Meet & Greet service at Luton airport based just outside North London and Stansted airport.",
  },
  {
    heading: "LONDON CITY",
    description:
      "Flying from London City Airport in East London? We can transfer you fast.",
  },
  {
    heading: "ALL TRANSFER TYPES",
    description:
      "We can offer any kind of transfer - short or long distance at affordable rates.",
  },
  {
    heading: "INTER-AIRPORT",
    description:
      "Need to transfer from one airport to another? Call us on 0208 459 9999 to discuss your requirements.",
  },
  {
    heading: "FREQUENT FLYER?",
    description:
      "We can set-up a business account for your business. Email us here.",
  },
];
// overview component
export default function OverView() {
  return (
    <section className="container">
      <div className={style.overviewSec}>
        <h2>Safe vehicles for every airport transfer</h2>
        <ul className={style.vehicleList}>
          {vehicleImgs.map((itm, indx) => (
            <li key={indx}>
              <img src={itm} alt="airpotbee-vehicle" />
            </li>
          ))}
        </ul>
        <ul className={style.pointList}>
          {points.map((itm, indx) => (
            <li key={indx}>
              <span className={style.overviewIcon}>
                <CheckCircleIcon style={{ color: "#ffcc00" }} />
              </span>
              <span>
                <h3>{itm.heading}</h3>
                <p>{itm.description}</p>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
